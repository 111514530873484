import { template as template_4f7510bc44ea4f09b97ebf8c64f1ac06 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4f7510bc44ea4f09b97ebf8c64f1ac06(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
