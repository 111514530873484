import { template as template_2d5797ae7dd14af0914cf6ee530ba84b } from "@ember/template-compiler";
const FKText = template_2d5797ae7dd14af0914cf6ee530ba84b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
