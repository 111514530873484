import { template as template_b6ee5303cd0048b6ac2168e98cb1b95b } from "@ember/template-compiler";
const SidebarSectionMessage = template_b6ee5303cd0048b6ac2168e98cb1b95b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
