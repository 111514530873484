import { template as template_86a1e27b5fab4732a7977e386f032b13 } from "@ember/template-compiler";
const WelcomeHeader = template_86a1e27b5fab4732a7977e386f032b13(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
